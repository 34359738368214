.MainDash {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-y: auto;
}
.DisplayParties {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 100%;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 768px) {
  .MainDash {
    align-items: center;
  }
}

.ButtonBookAClass {
  background-color: #01d595;
  color: white;
  padding: 15px;
  text-align: center;
  width: 100px;
  margin-left: 35px;
  border-radius: 5px;
  cursor: pointer;
}
.ButtonCloseModalBookAClass {
  color: black;
  padding: 15px;
  text-align: center;
  width: 100px;
  margin-left: 35px;
  border-radius: 5px;
  cursor: pointer;
}

.ForgotPasswordContener {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px auto;
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 10px #011d9b;
  margin-left: 100px;
  margin-right: 100px;
  align-items: center;
  justify-content: center;
}

.ResetPassWordButton {
  display: flex; /* Permet de centrer horizontalement et verticalement */
  align-items: center; /* Centre verticalement le contenu */
  justify-content: center; /* Centre horizontalement le contenu */
  text-align: center;
  background-color: #e35508;
  color: white; /* Pour rendre le texte visible */
  padding: 10px; /* Ajuste l’espace autour du texte */
  border-radius: 5px; /* Optionnel : pour les coins arrondis */
  cursor: pointer; /* Indique que c'est cliquable */
}

.ButtonGoBackResetPAssWord {
  display: flex; /* Permet de centrer horizontalement et verticalement */
  align-items: center; /* Centre verticalement le contenu */
  justify-content: center; /* Centre horizontalement le contenu */
  text-align: center;
  background-color: #faf7ef;
  color: #e35508; /* Pour rendre le texte visible */
  border: solid 1px #e35508;
  padding: 10px; /* Ajuste l’espace autour du texte */
  border-radius: 5px; /* Optionnel : pour les coins arrondis */
  cursor: pointer; /* Indique que c'est cliquable */
  margin-top: 30px;
  width: 100px;
  transition: background-color 0.3s ease;
}

.ButtonGoBackResetPAssWord:hover {
  display: flex; /* Permet de centrer horizontalement et verticalement */
  align-items: center; /* Centre verticalement le contenu */
  justify-content: center; /* Centre horizontalement le contenu */
  text-align: center;
  background-color: #e35508;
  color: white; /* Pour rendre le texte visible */
  border: solid 1px #e35508;
  padding: 10px; /* Ajuste l’espace autour du texte */
  border-radius: 5px; /* Optionnel : pour les coins arrondis */
  cursor: pointer; /* Indique que c'est cliquable */
  margin-top: 30px;
  width: 100px;
}

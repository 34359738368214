.NoLogIn {
  display: grid;
  background-color: rgb(255, 255, 255);
  border-radius: 1rem;
  gap: 10px;
  color: #ffffff; /* Couleur du texte */
  padding: 20px;
  width: 350px;
}
.contenerLogIn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hoverEffect {
  background-color: #e35508;
  padding: 15px;
  width: 92%;
  border-radius: 5px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  border: solid 1px #e35508;
  cursor: pointer;
}

.hoverEffect:hover {
  background-color: #faf7ef; /* Change le fond en blanc lors du survol */
  color: #e35508;
}

.ButtonFirstConnection {
  width: 92%;
  color: white;
  background-color: #011d9b;
  border-radius: 5px;
  text-align: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: solid 1px #011d9b;
  margin-bottom: 20px;
  margin-top: 10px;
}

.ButtonFirstConnection:hover {
  background-color: #faf7ef; /* Change le fond en blanc lors du survol */
  color: #011d9b;
}

.ApplyForMembershipcontener {
  margin-top: 30px;
  padding-top: 30px;
  border-top: solid 1px #e35508;
  width: 100%;
}

.ApplyForMembershipButton {
  color: #e35508;
  background-color: #faf7ef;
  border: solid 1px #e35508;
  border-radius: 5px;
  text-align: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ApplyForMembershipButton:hover {
  background-color: #e35508; /* Change le fond en blanc lors du survol */
  color: white;
}

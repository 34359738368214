.contenerHeaderBackOffice {
  height: 100%;
  width: 100%;
  grid-column: span 3;
  grid-row: span 1;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px #e35508;
  background-color: #011d9b;
}

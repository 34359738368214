.ContenerSecurity {
  display: flex;
  flex-direction: column;
}

.ButtonChangePass {
  text-align: center;
  background-color: #e35508;
  padding: 15px;
  color: white;
  border-radius: 5px;
  width: 200px;
  margin-top: 30px;
  cursor: pointer;
}

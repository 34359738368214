.workshop-scheduler-Mobile {
  width: 100%;
  overflow-y: auto;
  margin: 20px auto;
}

.navigationdivMobileModeBookAClass {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  border: solid 1px #bdbdbd;
  padding: 20px;
  background-color: white;
  border-radius: 30px;
}

.grid-MobileModeBookAClass {
  display: flex;
  flex-direction: column;
}

.row-MobileModeBookAClass {
  display: flex;
}

.header-MobileModeBookAClass {
  background-color: white;
  color: black;
}

.cell-MobileModeBookAClass {
  flex: 1;
  padding: 10px;
  min-height: 50px;
  text-align: center;
  background-color: #faf7ef;
}

.hour-header-MobileModeBookAClass {
  min-width: 60px;
}
.Grid-MobileModeBookAClass {
  display: flex;
  flex-direction: column;
}

.workshopContener-MobileModeBookAClass {
  margin: 10px;
  box-shadow: 0 0 10px #000000;
  padding: 15px;
  border-radius: 5px;
  background-color: white;
}
.rowworkshop-MobileModeBookAClass {
  margin-bottom: 15px;
}

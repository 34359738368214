.ContenerBookAsocialEvent {
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 10px #603d88;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 25px;
}

.navigation button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigation button:hover {
  background-color: #0056b3;
}

.grid {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.cell {
  flex: 1;
  padding: 10px;
  min-height: 50px;
  text-align: center;
}

.hour-cell {
  background-color: #f1f1f1;
  font-weight: bold;
}

.header {
  background-color: white;
  color: black;
}

.hour-header {
  min-width: 60px;
}

.workshopBookSocialEvent {
  display: flex;
  flex-direction: column;
  background-color: #603d88;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0;
  box-shadow: 0 4px 10px #d9d9d9;
}

.ActivityTypeSelectionBookSocialEvent {
  padding: 15px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 5px;
  border: solid 1px #c8c2b8;
  margin-right: 15px;
  cursor: pointer;
}
.ActivityTypeSelectionBookSocialEvent:hover {
  background-color: #603d88;
  color: white;
}

.ContenerFirstConnectionProcess {
  display: flex; /* Active Flexbox */
  justify-content: center; /* Centre horizontalement le contenu */
  width: 100%;
  height: 70vh;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 10px #e35508;
  margin-left: 100px;
  margin-right: 100px;
  overflow-y: auto;
}
.ConfirmButtonDenieMode {
  color: white;
  background-color: #bdbdbd;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
}
.ConfirmButtonNormalMode {
  color: white;
  background-color: #e35508;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
}

.MembershipCardSelected-FirstConnectionPage {
  background-color: white;
  color: black;
  padding: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  max-width: 300px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4); /* Ombre noire */
  border: solid 2px #e35508;
}
.ButtonBack-FirstConnectionProcess {
  display: flex;
  background-color: #faf7ef; /* Ajoutez une couleur de fond pour le bouton */
  color: #e35508; /* Couleur du texte du bouton */
  padding: 10px;
  border-radius: 5px; /* Arrondissez les coins du bouton */
  cursor: pointer; /* Change le curseur pour indiquer qu'il s'agit d'un bouton */
  align-items: center;
  justify-content: center;
  text-align: center;
  border: solid 1px #e35508;
  width: 150px;
  margin-right: 10px;
}
.EntertoTheSanctuaryText {
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 30px;
  color: #011d9b;
}
.PassText {
  font-weight: bold;
  margin-bottom: "10px";
  color: #011d9b;
  margin-bottom: 10px;
}
.ChooseYourMemberShipText {
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 30px;
  color: #011d9b;
  text-align: center;
}
.ContenerMembershipDetail-FirstConnectionPage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .ContenerFirstConnectionProcess {
    display: flex; /* Active Flexbox */
    justify-content: center; /* Centre horizontalement le contenu */
    width: 100%;
    height: 90vh;
    margin: 20px auto;
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: white;
    box-shadow: none;
    margin-left: 10px;
    margin-right: 10px;
    overflow-y: auto;
  }
  .EntertoTheSanctuaryText {
    font-size: 20px;
  }

  .ConfirmButtonDenieMode {
    width: 75px;
  }
  .ConfirmButtonNormalMode {
    width: 75px;
  }
  .ButtonBack-FirstConnectionProcess {
    width: 75px;
  }
  .ChooseYourMemberShipText {
    font-size: 20px;
  }
  .ContenerMembershipDetail-FirstConnectionPage {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}

/* styles.css */
.contenerQuestion {
  height: 80vh;
  margin: 20px;
  background-color: white;
  box-shadow: 0 4px 10px rgba(1, 29, 155);
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.TextContener {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  color: #011d9b;
  align-items: center;
}
.TextWelcome {
  font-size: 42px;
  margin-bottom: 15px;
  font-weight: bold;
}
.TextWhatDo {
  font-size: 32px;
}
.ChoiceContener {
  display: flex;
  flex-direction: row;
  align-items: center; /* Centre les éléments verticalement */
  justify-content: center; /* Centre les éléments horizontalement */
  margin-top: 50px;
}
.ContenerBoxchoice {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 25px;
  align-items: center;
  margin-right: 25px;
}
.shadow1 {
  box-shadow: 0 4px 10px #e35508;
}
.shadow2 {
  box-shadow: 0 4px 10px #01d595;
}
.shadow3 {
  box-shadow: 0 4px 10px #603d88;
}

.ButtonLetSBook {
  border-radius: 5px;
  padding: 15px;
  color: white;
  padding-left: 100px;
  padding-right: 100px;
  cursor: pointer;
}

.ButtonLetSBookColor1 {
  background-color: #e35508;
}
.ButtonLetSBookColor2 {
  background-color: #01d595;
}
.ButtonLetSBookColor3 {
  background-color: #603d88;
}

@media screen and (max-width: 1400px) {
  .ChoiceContener {
    flex-wrap: wrap;
    margin-top: 25px;
  }
  .ContenerBoxchoice {
    margin-bottom: 25px;
  }
  .contenerQuestion {
    overflow-y: auto;
  }
}

@media screen and (max-width: 700px) {
  .TextWelcome {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .TextWhatDo {
    font-size: 22px;
  }
  .ContenerBoxchoice {
    margin-right: 0px;
  }
  .ChoiceContener {
    margin-top: 0px;
  }
}

.ProfilContener {
  margin: 20px auto;
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 10px #011d9b;
}
.HearderProfilPage {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.TextHearderProfilPage {
  color: #011d9b;
  font-size: 1.5rem; /* Ajustez la taille pour obtenir la grandeur souhaitée */
  font-weight: bold; /* Applique le gras */
  margin-bottom: 30px;
}
.MenuHearderProfilPage {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.ItemMenuSelected {
  border-bottom: solid 3px #011d9b;
}
.MenuItem {
  cursor: pointer;
}
.MenuItemSelected {
  cursor: pointer;
  border-bottom: solid 3px #011d9b;
}
.MenuItem:hover {
  border-bottom: solid 3px #011d9b;
}

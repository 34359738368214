.appointment-scheduler {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 10px #e35508;
}

.alignItems {
  text-align: center;
}
.step {
  margin-bottom: 20px;
}

.time-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.time-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.time-button.selected {
  background-color: #0353a8; /* Couleur de fond pour l'heure sélectionnée */
  color: white; /* Couleur du texte pour l'heure sélectionnée */
  border-color: #e35508; /* Couleur de bordure pour l'heure sélectionnée */
}

.time-button:hover {
  background-color: #0353a8;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button,
.back-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover,
.back-button:hover {
  background-color: #218838;
}

.ContenerCalendar {
  display: flex;
  flex-direction: column;
}
.ButtonBackCalendar {
  background-color: #faf7ef; /* Ajoutez une couleur de fond pour le bouton */
  color: #e35508; /* Couleur du texte du bouton */
  padding: 10px 20px; /* Ajoutez un peu de remplissage */
  border-radius: 5px; /* Arrondissez les coins du bouton */
  cursor: pointer; /* Change le curseur pour indiquer qu'il s'agit d'un bouton */
  padding-left: 100px;
  padding-right: 100px;
  border: solid 1px #e35508;
}
.ButtonNextCalendar {
  background-color: #e35508; /* Ajoutez une couleur de fond pour le bouton */
  color: #faf7ef; /* Couleur du texte du bouton */
  padding: 10px 20px; /* Ajoutez un peu de remplissage */
  border-radius: 5px; /* Arrondissez les coins du bouton */
  cursor: pointer; /* Change le curseur pour indiquer qu'il s'agit d'un bouton */
  padding-left: 100px;
  padding-right: 100px;
  border: solid 1px #e35508;
}
.center-container {
  display: flex; /* Utilise Flexbox */
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement (si nécessaire) */
  height: 100px; /* Hauteur de la div parent pour un centrage vertical */
}
/* styles.css */
.custom-datepicker {
  font-size: 1.5rem; /* Ajuste la taille de la police pour tout le calendrier */
  width: 100%; /* S'assure que le calendrier prend toute la largeur du conteneur */
}

.react-datepicker {
  font-size: 1.5rem; /* Ajuste la taille de la police du calendrier */
}

.react-datepicker__header {
  background-color: white; /* Couleur de fond de l'en-tête du calendrier */
  color: white; /* Couleur du texte de l'en-tête */
  font-size: 1.5rem; /* Ajuste la taille de la police de l'en-tête */
}

.react-datepicker__day {
  display: inline-flex; /* Affiche les jours en ligne */
  justify-content: center; /* Centre le texte horizontalement */
  align-items: center; /* Centre le texte verticalement */
  width: 6rem; /* Largeur des jours */
  height: 3rem; /* Hauteur des jours */
  line-height: 2.5rem; /* Centre le texte verticalement */
  color: black;
}

.react-datepicker__day--selected {
  background-color: #e35508; /* Couleur pour la date sélectionnée */
  color: white; /* Couleur du texte pour la date sélectionnée */
}

.react-datepicker__day--highlighted {
  background-color: rgba(
    26,
    165,
    77,
    0.2
  ); /* Couleur pour les jours surlignés */
}

.react-datepicker__current-month {
  font-size: 1.8rem; /* Taille de la police pour le mois courant */
}

.react-datepicker__day--disabled {
  color: #ccc; /* Couleur des jours désactivés */
  background-color: transparent; /* Arrière-plan transparent pour les jours désactivés */
}

.react-datepicker__week {
  display: flex; /* Utilise flexbox pour afficher les semaines en ligne */
  justify-content: center; /* Centre les jours de la semaine */
}

.ButtonGoBackToHomePage {
  background-color: #011d9b;
  color: white;
  text-align: center;
  width: 300px;
  padding: 15px;
  margin-top: 35px;
  border-radius: 5px;
  cursor: pointer;
}

.ButtonGoBackToHomePage:hover {
  background-color: white;
  color: #011d9b;
  border: solid 1px #011d9b;
}

@media screen and (max-width: 1400px) {
  .appointment-scheduler {
    width: 90%;
  }
  .react-datepicker__day {
    width: 4rem; /* Largeur des jours */
    height: 2rem; /* Hauteur des jours */
  }
}

@media screen and (max-width: 900px) {
  .appointment-scheduler {
    width: 90%;
  }
  .react-datepicker__day {
    width: 2.5rem; /* Largeur des jours */
    height: 1.5rem; /* Hauteur des jours */
  }
}

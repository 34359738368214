.ContenerMembershipDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.MembershipCardSelected {
  background-color: #e35508;
  color: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  max-width: 300px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4); /* Ombre noire */
}

.MembershipCard {
  background-color: white;
  color: black;
  padding: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  max-width: 300px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4); /* Ombre noire */
}
.MembershipCard:hover {
  border: solid 2px #e35508;
}
.TitleMembershipCardSelected {
  color: white;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center; /* Centrage horizontal du texte */
  display: flex;
  align-items: center; /* Centrage vertical si la div a une hauteur définie */
  justify-content: center; /* Centrage horizontal pour tout le contenu */
}
.TitleMembershipCard {
  color: black;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center; /* Centrage horizontal du texte */
  display: flex;
  align-items: center; /* Centrage vertical si la div a une hauteur définie */
  justify-content: center; /* Centrage horizontal pour tout le contenu */
}
.endMembership {
  text-align: center;
  margin-top: 35px;
}

.ButtonCancelMembership {
  margin-top: 50px;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px white;
  text-align: center;
  cursor: pointer;
}

.ButtonCancelMembership:hover {
  background-color: white;
  transition: 1s;
  color: #e35508;
}
.ButtonUpgradeMembership {
  margin-top: 50px;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px black;
  text-align: center;
  cursor: pointer;
}
.ButtonUpgradeMembership:hover {
  background-color: #e35508;
  transition: 1s;
  color: white;
}

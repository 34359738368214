.UserDetailContener {
  display: flex;
  flex-direction: row;
}
.GeneralInformations {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-right: 80px;
}
.AccountInformations {
  display: flex;
  flex-direction: column;
  width: 250px;
}
.TextGeneralInformations {
  font-weight: bold;
  color: #011d9b;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .UserDetailContener {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

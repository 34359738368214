html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
:root {
  --yellow: linear-gradient(180deg, #f8d49a -146.42%, #fad79d -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --BlackAkasha: #faf7ef;
  --purple: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);
  --pink: #ff919d;
  --glass: rgba(180, 23, 3, 0.54);
  --glassNoLog: rgba(9, 9, 9, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
}
.App {
  background: #faf7ef;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
}
.AppGlass {
  display: grid;
  height: 100%;
  width: 100%;
  background-color: var(--BlackAkasha);
  overflow: hidden;
  grid-template-columns: 11rem auto 11rem;
  grid-template-rows: 84px auto;
}

@media screen and (max-width: 1400px) {
  .AppGlass {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .AppGlass {
    grid-template-columns: 1fr;
    overflow-x: auto;
  }
  .AppGlass::-webkit-scrollbar {
    display: none;
  }
}

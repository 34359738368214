.ContenerMainDashBoradEvent {
  padding: 70px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.MenuClassSocialEvent {
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  border: solid 1px #011d9b;
}
.CellMenuClassSocialEvent {
  padding: 10px;
  color: #011d9b;
  cursor: pointer;
  border-right: solid 1px #011d9b;
}
.CellMenuClassSocialEvent:hover {
  background-color: #011d9b;
  color: white;
}
.SelectAreaMainDashBoradEvent {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.MainDashBoradEvent {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.ContenerMenuVerticalBackOffice {
  display: flex;
  flex-direction: column;
  background-color: #011d9b;
}
.ContenerLinkMenuVerticalBackOffice {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 120px;
  text-align: center;
}
.CellLinkMenuVerticalBackOffice {
  color: white;
  padding: 15px;
  cursor: pointer;
}

.CellLinkMenuVerticalBackOffice:hover {
  font-weight: bold;
}

.ButtonCancelBooking {
  background-color: #011d9b;
  color: white;
  padding: 15px;
  text-align: center;
  width: 100px;
  margin-left: 35px;
  border-radius: 5px;
  cursor: pointer;
}

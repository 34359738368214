/* BookTableSanctuaryMobileMode.css */
.content {
  display: flex;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out; /* Transition pour max-height et padding */
  padding: 0 10px; /* Ajout d'un padding pour le contenu */
  color: black;
  margin-left: 5px;
  margin-left: 5px;
  align-items: center;
  justify-content: center;
}

.content.open {
  max-height: 1000px; /* Limite maximale pour l'animation */
  padding: 10px 10px; /* Padding lors de l'ouverture */
  background-color: #ffffff; /* Couleur de fond lors de l'ouverture */
  border-bottom: 1px solid #ccc; /* Bordure pour le contenu ouvert */
  box-shadow: 0 5px 5px rgba(227, 85, 8, 0.5);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Onglet {
  padding: 10px;
  background-color: #ffffff;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 15px rgba(227, 85, 8, 0.5);
  margin: 5px;
  font-weight: bold;
  border-radius: 5px;
}

.OngletOpen {
  padding: 10px;
  background-color: #ffffff;
  cursor: pointer;
  margin-left: 5px;
  margin-left: 5px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ButtonBackCalendarMobileMode {
  background-color: #faf7ef; /* Ajoutez une couleur de fond pour le bouton */
  color: #e35508; /* Couleur du texte du bouton */
  padding: 10px 20px; /* Ajoutez un peu de remplissage */
  border-radius: 5px; /* Arrondissez les coins du bouton */
  cursor: pointer; /* Change le curseur pour indiquer qu'il s'agit d'un bouton */
  padding-left: 50px;
  padding-right: 50px;
  border: solid 1px #e35508;
  margin-right: 10px;
}
.ButtonNextCalendarMobileMode {
  background-color: #e35508; /* Ajoutez une couleur de fond pour le bouton */
  color: #faf7ef; /* Couleur du texte du bouton */
  padding: 10px 20px; /* Ajoutez un peu de remplissage */
  border-radius: 5px; /* Arrondissez les coins du bouton */
  cursor: pointer; /* Change le curseur pour indiquer qu'il s'agit d'un bouton */
  padding-left: 50px;
  padding-right: 50px;
  border: solid 1px #e35508;
}
